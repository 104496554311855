import React from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';

const Home = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      flex="1"
      width="100%"
      position="relative"
      textAlign="center"
      p={4}
      pt={16}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        bgImage="url(/zano_logo.png)"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="30%"
        opacity="0.05"
      />

      <Text
        as="span"
        className="neon-text"
        opacity="90%"
        fontWeight="bold"
        fontSize="3xl"
        mb={4}
      >
        We Are Zano
      </Text>
      
      <Text
        fontSize="lg"
        opacity="90%"
        maxWidth="600px"
      >
        Zano is an ultra-secure, multi-asset, decentralized financial ecosystem.
        It's about to change the world...
      </Text>
    </Flex>
  );
};

export default Home;