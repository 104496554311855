import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import Navbar from './components/Navbar';
import StakingCalculator from './components/StakingCalculator';
import Footer from './components/Footer';
import { Box, Flex, ChakraProvider } from '@chakra-ui/react';

function App() {
  const [zanoData, setZanoData] = useState({
    price: null,
    fetchError: false
  });

  useEffect(() => {
    const fetchZanoPrice = async () => {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=zano&vs_currencies=usd&include_24hr_change=true');

        if (!response.ok) {
          throw new Error(`API responded with status ${response.status}`);
        }

        const data = await response.json();
        setZanoData({
          price: data.zano.usd,
          fetchError: false
        });
        console.log("Fetched ZANO price:", data.zano.usd);
      } catch (error) {
        console.error('Failed to fetch ZANO price:', error);
        setZanoData({
          price: null,
          fetchError: true
        });
      }
    };

    fetchZanoPrice();

    const intervalId = setInterval(fetchZanoPrice, 60000); // Every minute

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ChakraProvider>
      <Box
        minHeight="100vh"
        bgImage="url('/white_bg.jpg')"
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
      >
        <Router>
          <Flex direction="column" minHeight="100vh">
            <Box width="100%">
              <Navbar zanoData={zanoData} />
            </Box>
            
            <Flex
              direction="column"
              flex="1"
              width="100%"
              maxWidth="1500px"
              mx="auto"
              px={{ base: 4, md: 8, lg: 16, xl: 32 }}
              py={4}
              bg="white"
              mt={4}  // Added margin top to create space between header and main content
            >
              <Box flex="1" mt={15} overflowY="auto">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/staking-simulator" element={<StakingCalculator zanoData={zanoData} />} />
                </Routes>
              </Box>
            </Flex>

            <Box width="100%">
              <Footer />
            </Box>
          </Flex>
        </Router>
      </Box>
    </ChakraProvider>
  );
}

export default App;