import React, { useState, useEffect, useRef } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { Box, Grid, Flex, Link, FormControl, Radio, RadioGroup, Stack, Text, Input, Button } from '@chakra-ui/react';

import '../styles/main.css';

function StakingCalculator({ zanoData  }) {
  const [stakedZano, setStakedZano] = useState('');
  const [interestRate, setInterestRate] = useState(null);
  const [inputStakedZano, setInputStakedZano] = useState('');
  const [dailyReward, setDailyReward] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [chartRendered, setChartRendered] = useState(false);
  const chartRef = useRef(null);
  const [timeFrame, setTimeFrame] = useState('1y');
  const [rewardData, setRewardData] = useState({
    labels: Array.from({ length: 13 }, (_, i) => `${i}`),
    datasets: [
      {
        label: 'Total Value (Staked + Rewards)',
        data: new Array(13).fill(0),
        fill: false,
        backgroundColor: 'rgb(39, 76, 255)',
        borderWidth: 1,
        borderColor: 'rgba(39, 76, 255, 0.2)',
      },
    ],
  });
  const [yAxisMax] = useState(100);
  const createGradient = (ctx) => {
    const gradient = ctx.createLinearGradient(0, ctx.canvas.height, ctx.canvas.width, 0);
    gradient.addColorStop(0, 'rgba(39, 76, 255)');
    gradient.addColorStop(1, 'rgba(20, 209, 214)');
    return gradient;
  };
  const createTransparentGradient = (ctx) => {
    const transparentGradient = ctx.createLinearGradient(0, ctx.canvas.height, ctx.canvas.width, 0);
    transparentGradient.addColorStop(0, 'rgba(39, 76, 255, 0.5)');
    transparentGradient.addColorStop(0.2, 'rgba(39, 76, 255, 0.5)');
    transparentGradient.addColorStop(0.5, 'rgba(20, 209, 214, 0.5)');
    transparentGradient.addColorStop(1, 'rgba(20, 209, 214, 0.5)');
    return transparentGradient;
  };
  const setupGradient = () => {
    if (chartRef.current && chartRef.current.ctx) {
      const gradient = createGradient(chartRef.current.ctx);
      const transparentGradient = createTransparentGradient(chartRef.current.ctx);

  
      setRewardData(prevData => ({
        ...prevData,
        datasets: prevData.datasets.map(dataset => ({
          ...dataset,
          borderColor: gradient,
          backgroundColor: transparentGradient,
          pointBorderColor: gradient,
          pointBackgroundColor: gradient,
          fill: true,
        }))
      }));
    }
  };
  const formatNumberWithCommas = (value) => {
    return new Intl.NumberFormat('en-US').format(value);
  };
  const formatReward = (reward) => {
    return reward !== null ? formatNumberWithCommas(parseFloat(reward.toFixed(5))) : '0';
  };
  
  useEffect(() => {
    if (chartRef.current) {
      setChartRendered(true);
    }
  }, [chartRef]);
  
  useEffect(() => {
    if (chartRendered) {
      setupGradient();
    }
  }, [chartRendered]);

  useEffect(() => {
    const fetchInterestRate = async () => {
      const apiResponse = await fetch('https://explorer.zano.org/api/get_visibility_info');
      const data = await apiResponse.json();
      setInterestRate(data.apy);
    };

    fetchInterestRate();
  }, []);

  const calculateRewards = async (e) => {
    e.preventDefault();
    if (!interestRate) {
      console.error('Interest Rate not loaded yet');
      return;
    }

    const parsedInputStakedZano = parseFloat(inputStakedZano) || 0;
    setStakedZano(parsedInputStakedZano);
  
    const dailyEarnings = parsedInputStakedZano * (interestRate / 100) / 365;
    setDailyReward(dailyEarnings);
  
    const months = timeFrame === '1m' ? 1 : timeFrame === '1y' ? 12 : timeFrame === '3y' ? 36 : 60;
    let newLabels = Array.from({ length: months + 1 }, (_, i) => i.toString());
    let newDataPoints = [parsedInputStakedZano];
    let cumulativeReward = parsedInputStakedZano;
    for (let i = 1; i <= months; i++) {
      cumulativeReward += dailyEarnings * 30; // Assuming 30 days in a month
      newDataPoints.push(cumulativeReward);
    }
  
    updateChartData(newDataPoints, newLabels);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;

    // Regex to match a number that doesn't start with 0 unless followed by a decimal point
    const regex = /^(?!0\d)\d*(\.\d+)?$/;

    if (value === '' || regex.test(value)) {

        const numericValue = parseFloat(value);
  
        if (numericValue > 15000000) {
          setErrorMessage("Can't stake more than the current supply");
        } else if (value.includes('.') && value.split('.')[1].length > 12) {
          // Check for more than 12 decimal places
          setErrorMessage("Limited to 12 decimal places");
        } else {
          setErrorMessage('');
          setInputStakedZano(value);
        }
      } else {
        setErrorMessage("Please input a numeric value");
    }
  };

  const updateChartData = (newDataPoints, newLabels) => {
    if (chartRef.current && chartRef.current.ctx) {
      const gradient = createGradient(chartRef.current.ctx);
      const transparentGradient = createTransparentGradient(chartRef.current.ctx);

      setRewardData({
        labels: newLabels,
        datasets: [
          {
            label: 'Total Value (Staked + Rewards)',
            data: newDataPoints,
            fill: true,
            borderWidth: 1,
            borderColor: gradient,
            backgroundColor: transparentGradient, 
            pointBorderColor: gradient,
            pointBackgroundColor: gradient,
          },
        ],
      });
    }
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Total ZANO (Staked + Rewards)', 
        padding: {
          bottom: 25,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'ZANO',
        },
        beginAtZero: false,
        min: parseFloat(stakedZano) || 0,
        ticks: {
          padding: 10,
        },
      },
      x: {
        title: {
          display: true,
          text: 'Months',
        },
        ticks: {
          padding: 10,
        },
      },
    },
    elements: {
      point: {
        radius: 4,
      },
    },
  };

  const handleTimeFrameChange = (newTimeFrame) => {
    setTimeFrame(newTimeFrame);
  
    if (stakedZano) {
      const parsedStakedZano = parseFloat(stakedZano) || 0;
      
      const months = newTimeFrame === '1m' ? 1 
                   : newTimeFrame === '1y' ? 12 
                   : newTimeFrame === '3y' ? 36 
                   : 60; // Default to 60 months for '5y'
  
      const dailyEarnings = parsedStakedZano * (interestRate / 100) / 365;
      setDailyReward(dailyEarnings);

      let newLabels = Array.from({ length: months + 1 }, (_, i) => i.toString()); // Just the numbers
      let newDataPoints = [parsedStakedZano];
      let cumulativeReward = parsedStakedZano;
      for (let i = 1; i <= months; i++) {
        cumulativeReward += dailyEarnings * 30; // Assuming 30 days in a month
        newDataPoints.push(cumulativeReward);
      }
  
      updateChartData(newDataPoints, newLabels);
    }
  };
  
  return (
    <Flex
      direction="column"
      flexGrow={1}
      alignItems="center"
      p={4}
      pt={25}
    >
      {/* Conditional Wrapper: Flex for mobile, Grid for desktop */}
      <Box width="100%" display={{ base: "flex", md: "grid" }}
        flexDirection="column"
        gridTemplateColumns={{ md: "65% 35%" }}
        gridTemplateRows={{ md: "auto auto" }}
      >

        {/* Time Frame Selection and Chart Box */}
        <Box
        order={{ base: 1, md: 0 }}
        gridRow={{ md: "1 / 2" }}
        gridColumn={{ md: "1 / 2" }}
        >
          {/* Chart */}
          <Box height={{ base: "300px", md: "400px" }}>
            <Chart
              ref={chartRef}
              type='line'
              data={rewardData}
              options={chartOptions}
            />
          </Box>
          {/* Time Frame Selection */}
          <Box width="100%" pt={3} pb={2} ml={35}>
            <FormControl as="fieldset">
              {/* <FormLabel as="legend" fontSize="0.7em">Time Frame</FormLabel> */}
              <RadioGroup onChange={handleTimeFrameChange} value={timeFrame}>
                <Stack direction="row" spacing={1}>
                  <Radio size="sm" value="1m"><Text fontSize="0.7em">1 Month</Text></Radio>
                  <Radio size="sm" value="1y"><Text fontSize="0.7em">1 Year</Text></Radio>
                  <Radio size="sm" value="3y"><Text fontSize="0.7em">3 Years</Text></Radio>
                  <Radio size="sm" value="5y"><Text fontSize="0.7em">5 Years</Text></Radio>
                </Stack>
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>

        {/* Estimated Rewards Box */}
        <Box 
          order={{ base: 3, md: 0 }}
          gridRow={{ md: "1 / 2" }}
          gridColumn={{ md: "2 / 3" }}
          mt={{ base: 0, md: 20 }}
          mr={{ base: 0, md: 4 }}
          mb={{ base: 4, md: 40 }}
          ml={{ base: "0", lg: "12", xl: "12" }}
          pb="3"
          border="1px"
          borderColor="#bbb"
          borderRadius="md"
          backgroundColor="#fff"
        >
          <Text fontSize="small" fontWeight="semibold" textAlign="center" pt={3} mb={2}>
            Estimated Rewards
          </Text>
          {interestRate && (
          <Text fontSize="x-small" textAlign="center" color="gray.600">
            Current Interest Rate: {Number(interestRate).toFixed(3)}%
          </Text>
          )}
          <Box width="auto">
            <Grid templateColumns="0.5fr 1fr 1fr" fontSize="0.85em" gap={2} pt={7} pb={3} pl={3} pr={3}> 
              <>
                <Text>Daily:</Text>
                <Text fontWeight="600" color="rgba(32, 122, 241, 0.9)" align="right">{formatReward(dailyReward)}&nbsp; ZANO</Text>
                <Text color="rgba(89, 110, 134, 0.8)" align="right">(${zanoData.fetchError || zanoData.price === null ? 'N/A' : `${(dailyReward * zanoData.price).toFixed(2)}`} USD)</Text>
            
                <Text>Weekly:</Text>
                <Text fontWeight="600" color="rgba(32, 122, 241, 0.9)" align="right">{formatReward(dailyReward * 7)}&nbsp; ZANO</Text>
                <Text color="rgba(89, 110, 134, 0.8)" align="right">(${zanoData.fetchError || zanoData.price === null ? 'N/A' : `${(dailyReward * 7 * zanoData.price).toFixed(2)}`} USD)</Text>
            
                <Text>Monthly:</Text>
                <Text fontWeight="600" color="rgba(32, 122, 241, 0.9)" align="right">{formatReward(dailyReward * 30)}&nbsp; ZANO</Text>
                <Text color="rgba(89, 110, 134, 0.8)" align="right">(${zanoData.fetchError || zanoData.price === null ? 'N/A' : `${(dailyReward * 30 * zanoData.price).toFixed(2)}`} USD)</Text>
            
                <Text>Yearly:</Text>
                <Text fontWeight="600" color="rgba(32, 122, 241, 0.9)" align="right">{formatReward(dailyReward * 365)}&nbsp; ZANO</Text>
                <Text color="rgba(89, 110, 134, 0.8)" align="right">(${zanoData.fetchError || zanoData.price === null ? 'N/A' : `${(dailyReward * 365 * zanoData.price).toFixed(2)}`} USD)</Text>
              </>
            </Grid>
          </Box>
        </Box>

        {/* Input Box + Calculate Button */}
        <Flex 
          order={{ base: 2, md: 0 }}
          gridRow={{ md: "2 / 3" }}
          gridColumn={{ md: "1 / 3" }}
          justifyContent="center" 
          p={3}
          mb={3}
          mt={15}
        >
            <Box 
              as="form"
              onSubmit={calculateRewards}
              width={{ base: "70%", sm: "60%", md: "40%", lg: "30%", xl: "25%" }} // Adjust these breakpoints as necessary
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
            <Input
              type="number"
              value={inputStakedZano}
              onChange={handleInputChange}
              placeholder="enter ZANO amount"
              step="any"
              mb={3}
              focusBorderColor='#1d92e9'
            />
            {errorMessage && <Box fontSize="xs" color="red">{errorMessage}</Box>}
            <Button
              bg="white"
              color="#010101"
              border="1px"
              borderColor="#1d92e9"
              _hover={{ bg: "#1d92e9", color: "white" }}
              _active={{ bg: "#1D3DBF", color: "white" }}
              type="submit"
              disabled={!!errorMessage}
              my={3}
            >
              Calculate
            </Button>
          </Box>
        </Flex>
      </Box>


    {/* Footer Text */}
    <Text 
      width="75%"
      fontSize="xs"
      mt={4}
      p={3}
      pt={35}
    >
      These values are estimates based on the current interest rate. This fluctuates based on the total number of ZANO staked at any one time.
      To enable staking make sure you have your ZANO stored in the 
      <Link fontWeight="bold" href="https://zano.org/downloads" isExternal color="blue.500">
        &nbsp;official Desktop wallet&nbsp;
      </Link>
      and toggle the staking button in the sidebar to on. The wallet must be open and online in order to stake.
    </Text>
  </Flex>
  );
}

export default StakingCalculator;