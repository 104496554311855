import React from 'react';
import { Box, Flex, Link as ChakraLink, Image, Text, IconButton, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import { FiDownload } from 'react-icons/fi'; // Import download icon

const Navbar = ({ zanoData }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const linkStyles = {
    fontSize: '15',
    mr: 4,
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <Box width="100%" bg="white" borderBottom="1px solid rgba(0, 0, 0, 0.1)">
      <Flex
        as="nav"
        px={4}
        py={2}
        alignItems="center"
        justifyContent="space-between"
        maxWidth="1500px"
        mx="auto"
      >
        {/* Left Side - Logo */}
        <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
          <Image src="zano_logo.png" boxSize="50px" mr="0" alt="Logo" />
          <Text fontSize="lg" fontWeight="bold" ml="-1" color="rgba(0, 0, 0, 0.9)">
            WeAreZano
          </Text>
        </Link>

        {/* Middle - Links (visible until certain breakpoint) */}
        <Flex display={{ base: 'none', md: 'flex' }} alignItems="center" ml={10}>
          <ChakraLink as={Link} to="/staking-simulator" {...linkStyles}>
            Staking Simulator
          </ChakraLink>
          <ChakraLink href="/Zano Investors' Guidebook.pdf" download {...linkStyles}>
            <FiDownload style={{ marginRight: '4px' }} /> Investors' Guide
          </ChakraLink>
        </Flex>

        {/* Right Side - Price Ticker and Hamburger Menu */}
        <Flex alignItems="center" marginLeft="auto">
          {zanoData && (
            <Box
              border="1px solid rgba(0, 0, 0, 0.3)"
              backgroundColor="rgba(200, 200, 200, 0.1)"
              borderRadius="md"
              p={2}
              paddingLeft={3}
              paddingRight={3}
              mr={4}
              display="flex"
              alignItems="center"
            >
              <Text 
                color="rgba(0, 0, 0, 0.9)"
                fontWeight="bold"
                fontSize="sm"
                mr={2}
              >
                 1 ZANO:
              </Text>
              <Text 
                color="rgba(28, 151, 232)"
                fontWeight="bold"
                fontSize="sm"
                mr={0}
              >
                 {zanoData.fetchError || zanoData.price === null ? 'N/A' : `$${zanoData.price.toFixed(2)}`}
              </Text>
            </Box>
          )}

          {/* Hamburger menu */}
          <IconButton
            ref={btnRef}
            icon={<HamburgerIcon />}
            onClick={onOpen}
            display={{ base: 'block', md: 'none' }}
          />

        {/* Drawer */}
          <Drawer
            isOpen={isOpen}
            placement="left"
            onClose={onClose}
            finalFocusRef={btnRef}
          >
            <DrawerOverlay />
            <DrawerContent>
              <DrawerCloseButton />
              <DrawerHeader>We Are Zano</DrawerHeader>

              <DrawerBody>
                <Flex direction="column">
                  <ChakraLink as={Link} to="/" onClick={onClose} mb={4}>Home</ChakraLink>
                  <ChakraLink as={Link} to="/staking-simulator" onClick={onClose} mb={4}>Staking Simulator</ChakraLink>
                  <ChakraLink href="/Zano Investors' Guidebook.pdf" download onClick={onClose} display="flex" alignItems="center">
                    <FiDownload style={{ marginRight: '4px' }} /> Investors' Guide
                  </ChakraLink>
                </Flex>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Navbar;