import React from 'react';
import { Flex, Link as ChakraLink, Icon, Text, Box } from '@chakra-ui/react';
import { FaTwitter } from 'react-icons/fa'; // Import Twitter icon

const Footer = () => {
  return (
    <Box width="100%" bg="white" borderTop="1px solid rgba(0, 0, 0, 0.1)">
      <Flex
        as="footer"
        alignItems="center"
        justifyContent={{ base: 'center', md: 'space-between' }}
        flexDirection={{ base: 'column', md: 'row' }}
        px={4}
        py={2}
        maxWidth="1500px"
        mx="auto"
        textAlign="center"
      >
        <Flex alignItems="center" order={{ base: 3, md: 1 }} mb={{ base: 1, md: 0 }}>  {/* Reduced margin bottom */}
          <Text fontSize={{ base: 'xs', md: 'sm' }} mr={2}>
            Zano Alias:
          </Text>
          <Box bg="rgb(28, 151, 232)" color="white" px={2} py={1} borderRadius="md" display="flex" alignItems="center">
            <Text fontSize={{ base: 'xs', md: 'sm' }} fontWeight="bold">
              @wearezano
            </Text>
          </Box>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems="center"
          justifyContent={{ base: 'center', md: 'flex-end' }}
          order={{ base: 1, md: 2 }}
          mb={{ base: 1, md: 0 }}  // Reduced margin bottom
        >
          <ChakraLink
            href="https://twitter.com/WeAreZano"
            isExternal
            display="flex"
            alignItems="center"
            mb={{ base: 2, md: 0 }}
            mr={{ md: 8 }}
          >
            <Icon as={FaTwitter} w={{ base: 5, md: 6 }} h={{ base: 5, md: 6 }} color="blue.500" mr={2} />
            <Text fontSize={{ base: 'xs', md: 'sm' }}>WeAreZano</Text>
          </ChakraLink>
          <ChakraLink
            href="https://twitter.com/Zano_CA_Watch"
            isExternal
            display="flex"
            alignItems="center"
          >
            <Icon as={FaTwitter} w={{ base: 5, md: 6 }} h={{ base: 5, md: 6 }} color="blue.500" mr={2} />
            <Text fontSize={{ base: 'xs', md: 'sm' }}>Zano_CA_Watch</Text>
          </ChakraLink>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;